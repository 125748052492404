/* VideoInfoInstructions.css */

/* Video Info Instructions Section */
.video-info-instructions {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
    max-width: 800px;
    margin: 0 auto;
    font-family: 'VT323', monospace;
    font-size: 17px;
}

.video-info-instructions input[type="text"]:focus,
.video-info-instructions textarea:focus {
    outline: none;
    border-color: #ff8c00;
    box-shadow: 0 0 5px #ff8c00;
}

/* Video Info Instructions Title */
.video-info-instructions h2 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #ffa500;
    font-family: 'Pacifico', cursive; /* Retro font: VT323 */
}

/* Form Label */
.video-info-instructions label {
    font-size: 16px;
    margin-bottom: 5px;
    color: #333;
    font-family: 'VT323', monospace; /* Retro font: VT323 */
}

/* Form Input Container */
.video-info-instructions .form-input {
    margin-bottom: 20px;
    font-family: 'VT323', monospace;
}

/* Form Input */
.video-info-instructions input,
.video-info-instructions textarea {
    width: calc(100% - 20px);
    box-sizing: border-box;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 6px;
    margin-bottom: 10px;
}

/* Ready to Send Button (outside main box) */
.ready-to-send-button {
    margin-top: 20px;
}

/* Error Message */
.error-message {
    color: red;
    margin-top: 10px;
}

/* Submit Button */
.video-info-instructions button {
    background-color: #e74c3c;
    color: white;
    padding: 8px 10px; /* Decrease the vertical padding to reduce height */
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-family: 'VT323', monospace; /* Retro font: VT323 */
}

.video-info-instructions button:hover {
    background-color:#c0392b;
}


/* styles.css */

.custom-button {
    background-color: #ff9800 !important; /* Orange color */
    color: white;
    padding: 10px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    font-family: 'VT323, monospace'; /* Retro font: VT323 */
    transition: background-color 0.3s;
  }
  
  .custom-button:hover {
    background-color: #e57d00 !important; /* Darker orange color on hover */
  }
  

/* Cancel Button */
.video-info-instructions button[type="button"] {
    background-color: #e74c3c;
    color: white;
    margin-left: 10px;
}

.video-info-instructions button[type="button"]:hover {
    background-color: #c0392b;
}

.centered-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px; /* Add some top margin for spacing */
}

/* Save Button */
.centered-button button {
    background-color: #ff9800; /* Change background color to orange */
    color: white; /* Set text color to white */
    padding: 10px 20px; /* Adjust padding for height and width */
    border: none; /* Remove border */
    border-radius: 4px; /* Add border-radius for rounded edges */
    cursor: pointer;
    transition: background-color 0.3s;
    font-family: 'VT323', monospace; /* Retro font: VT323 */
}

.centered-button button:hover {
    background-color: #e57d00; /* Slightly darker orange color on hover */
}


/* Ready to Send Button (outside main box) */
.ready-to-send-button {
    margin-top: 20px; /* Add margin for spacing */
}

/* Ready to Send Button Styles */
.ready-to-send-button button {
    background-color: #ff9800;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-family: 'VT323', monospace; /* Retro font: VT323 */
}

.ready-to-send-button button:hover {
    background-color: #e57d00;
}

/* Ready to Send Container */
.ready-to-send-container {
    text-align: center;
    margin-top: 20px;
  }
  
  /* Ready to Send Message */
  .ready-to-send-message {
    font-size: 18px;
    margin-bottom: 10px;
    color: #ff9800; /* Green color */
    font-family: 'Pacifico', cursive;
  }
  
  /* Ready to Send Button */
.ready-to-send-container button {
    background-color: #ff9800; /* Orange color */
    color: white;
    padding: 10px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-family: 'VT323', monospace; /* Retro font: VT323 */
  }
  
  .ready-to-send-container button:hover {
    background-color: #e57d00; /* Slightly darker orange color on hover */
  }

  .loading-spinner {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top: 4px solid #ff9800; /* Change color to match your button */
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
    margin-right: 5px; /* Adjust spacing */
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }


  .ready-to-send-container .loading-spinner {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top: 4px solid #ff9800; /* Change color to match your button */
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
    margin-right: 5px; /* Adjust spacing */
  }


.success-message {
    color: rgb(162,96,70);
    margin-top: 10px;
    margin-bottom: 10px;
  }

/* Add these styles for the dropdown menu */
.video-info-instructions select#videoVisibility {
    width: calc(100% - 20px);
    box-sizing: border-box;
    padding: 10px;
    border-color: #ff8c00 !important;
    box-shadow: 0 0 5px #ff8c00;
    border-radius: 6px;
    margin-bottom: 10px;
    cursor: pointer; /* Add pointer cursor for better UX */
  }
  
  /* Style the dropdown arrow */
  .video-info-instructions select#videoVisibility::-ms-expand {
    display: none; /* Hide the default arrow on IE/Edge */
  }
  
  .video-info-instructions select#videoVisibility::after {
    content: '\25BC'; /* Unicode character for a downward arrow */
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    pointer-events: none; /* Ensure the arrow does not interfere with clicks */
    color: #333; /* Set the arrow color */
    border-color: #ff8c00 !important;
    box-shadow: 0 0 5px #ff8c00;
  }
  