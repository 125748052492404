/* VideoUpload.css */

.upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; 
  height: 100vh;
  background-color: #ffffff;
}

.upload-title {
  color: #ffa500;
  font-size: 2rem;
  margin-bottom: 2rem;
}

.upload-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.upload-form input,
.upload-form textarea {
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: none;
  border-radius: 5px;
  background-color: #141414;
  color: #fff;
}

/* Hide the default text of the file input */
.file-input {
  display: none;
}

.file-label {
  width: 100%;
  padding: 0.5rem;
  background-color: #e60000;
  color: #fff;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  position: relative;
  font-family: 'VT323', monospace;
  transition: background-color 0.3s;
}

.file-label:hover {
  background-color: #ff4d4d;
}

.upload-button {
  width: 100%;
  padding: 0.5rem;
  background-color: #ffa500;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 1rem;
  font-family: 'VT323', monospace;
  transition: background-color 0.3s;
}

.upload-button:hover {
  background-color: #e57d00;
}

.progress-bar {
  width: 100%;
  height: 15px;
  background-color: #cccccc9e;
  border-radius: 5px;
  margin-top: 1rem;
}

.progress-bar-fill {
  height: 100%;
  background-color: #ffffff0e;
  border-radius: 5px;
}

.upload-status {
  margin-top: 1rem;
  font-size: 1.2rem;
  text-align: center;
  color: #fff;
  padding: 0.5rem;
  border-radius: 5px;
}


.selected-file-text {
  margin-top: 0.5rem;
  display: block;
  color: #777;
  font-family: 'VT323', monospace;
}

.upload-title{
  font-family: 'Pacifico', cursive;
}

.upload-status{
  font-family: 'VT323', monospace;
  color: #ffffff00;
}

.success {
  background-color: #ffffff; /* Orange color */
  color: rgb(162,96,70);
}

/* Error Message */
.error {
  background-color: #ffffff; /* Red color */
  color: #f44336;
}

.uploaded-video-container {
  margin-top: 10px;
}

.uploaded-video-title {
  margin-top: 40px;
  font-size: 2rem;
  color: #e74c3c;
  font-family: 'Pacifico', cursive;
}

.video-player-container1 {
  width: 585px; /* Set the width as needed */
  height: 400px; /* Set the height as needed */
}
