/* Reset default margin and padding */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  /* Global Styles */
  body {
    font-family: 'Arial', sans-serif;
    line-height: 1.6;
    color: #333;
    background-color: #f4f4f4;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  /* Header Styles */
.header {
    background-color: #fff;
    border-bottom: 1px solid #ddd;
    padding: 10px 0;
  }
  
  .navbar-brand {
    font-size: 24px;
    font-weight: bold;
    color: #ff9800; /* Orange color */
    text-transform: uppercase;
    letter-spacing: 2px; /* Increase letter spacing */
    font-family: 'VT323', monospace; /* Apply VT323 font for video game style */
  }
  
  .navbar-brand:hover {
    color: #e57d00; /* Slightly darker orange color on hover */
  }
  

  .retro-link {
    font-size: 18px;
    color: #ff9800; /* Orange color */
    text-transform: uppercase;
    letter-spacing: 1px;
    font-family: 'VT323', monospace; /* Apply VT323 font for video game style */
    transition: color 0.3s;
    padding: 10px 20px;
    border: 2px solid #ff9800; /* Orange color */
    border-radius: 5px;
    display: inline-block;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s, border-color 0.3s;
  }

  /* Add margin between words in .retro-link */
.retro-link::after {
  content: '';
  margin-left: 5px; /* Adjust the value to increase or decrease the space between words */
  margin-right: 5px;
}

.retro-link::before {
  content: '';
  margin-left: 5px; /* Adjust the value to increase or decrease the space between words */
  margin-right: 5px;
}
  
  .retro-link:hover {
    background-color: #ff9800; /* Orange color on hover */
    color: #000; /* Change text color to black */
    border-color: #e57d00; /* Slightly darker orange color on hover */
  }
  
  
  /* Hero Section Styles */
.hero-section {
    background-color: #f7f7f7;
    padding: 100px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  .hero-section h1 {
    font-size: 48px;
    margin-bottom: 20px;
    color: #ffa500; /* Orange color */
    width: 100%;
    font-family: 'Pacifico', cursive; /* Use a retro font */
  }
  
  .hero-section p {
    font-size: 20px;
    color: #777;
    width: 100%;
    margin-bottom: 30px;
    font-family: 'VT323', monospace; /* Another retro font */
  }
  
  .hero-title {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;
    width: 100%;
  }
  
  .hero-description {
    font-size: 18px;
    margin-bottom: 30px;
    color: #666;
    width: 100%;
  }
  /* ... Other CSS rules ... */

 .highlighted-text {
    background-color: #ff9800; /* Change to the desired background color */
    color: #000; /* Change text color to black */
    padding: 5px 10px;
    border-radius: 5px;
    display: inline-block;
  }
  
  
  
  .hero-benefit {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 15px;
    color: #444;
    width: 100%;
  }
  
  .gif-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    margin-top: 30px;
  }

  .retro-icon {
    font-size: 24px;
    margin-right: 10px;
    color: #ff9800; /* Adjust color as needed */
  }
  
  
  
  
  /* Features Section Styles */
  .features-section {
    padding: 80px 0;
  }
  
  .features-section h2 {
    font-size: 32px;
    margin-bottom: 40px;
    text-align: center;
  }
  
  .feature-item {
    margin-bottom: 40px;
  }
  
  .feature-icon {
    font-size: 36px;
    color: #007bff;
  }
  
  .feature-title {
    font-size: 24px;
    margin-top: 20px;
  }
  
  .feature-description {
    font-size: 18px;
    color: #777;
  }
  
  /* Get Started Section Styles */
  .get-started-section {
    padding: 80px 0;
    background-color: #fff;
    text-align: center;
  }
  
  .get-started-button {
    font-size: 20px;
    font-weight: bold;
    color: #fff;
    background-color: #007bff;
    padding: 15px 40px;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .get-started-button:hover {
    background-color: #0056b3;
  }
  
  /* About Section Styles */
  .about-section {
    padding: 80px 0;
  }
  
  .about-section h2 {
    font-size: 32px;
    margin-bottom: 40px;
    text-align: center;
  }
  
  .about-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .about-image {
    width: 45%;
  }
  
  .about-text {
    width: 45%;
    font-size: 18px;
    color: #777;
  }
  
  /* Testimonials Section Styles */
  .testimonials-section {
    padding: 80px 0;
    background-color: #f9f9f9;
  }
  
  .testimonials-section h2 {
    font-size: 32px;
    margin-bottom: 40px;
    text-align: center;
  }
  
  /* Footer Styles */
  .footer {
    border-top: 1px solid #ddd;
    padding: 20px 0;
    background-color: #333;
    color: #fff;
    text-align: center;
  }
  
  /* Media Queries */
  @media (max-width: 768px) {
    .about-content {
      flex-direction: column;
    }
  
    .about-image,
    .about-text {
      width: 100%;
      margin-bottom: 40px;
    }
  }
  