/* Settings Section */
.settings-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

/* Setting Section Title */
.settings-container h2 {
  font-size: 34px;
  text-align: center;
  margin-bottom: 30px;
  color: #ffa500;
  font-family: 'Pacifico', cursive;
}

/* Setting Section Subtitle */
.settings-container h3 {
  font-size: 25px;
  margin-bottom: 10px;
  margin-top: 25px;
  color: #ffa500;
  font-family: 'Pacifico', cursive;
}

/* Setting Item Container */
.setting-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

/* Setting Item Label */
.setting-item label {
  margin-right: 10px;
  font-family: 'VT323', monospace;
}

.setting-item-1 {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-family: 'VT323', monospace;
  font-size: 20px;
}

/* Setting Item Input */
.setting-item input[type='text'],
.setting-item input[type='email'],
.setting-item select {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 10px;
  flex-grow: 1;
}

.setting-item input[type='text']:focus{
  outline: none; /* Remove default blue outline */
  border-color: #ff8c00; /* Set border color to orange when focused */
  box-shadow: 0 0 5px #ff8c00; /* Add orange box-shadow when focused */
}

.setting-item select#project-select {
  outline: none; /* Remove default blue outline */
}


/* Setting Item Button */
.setting-item button.save-username-btn {
  padding: 5px 10px;
  background-color: #ffa500;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-family: 'VT323', monospace;
}

.setting-item button.save-username-btn:hover {
  background-color: #e57d00;
}

/* Delete, Cancel Subscription, and Delete Account Button Styles */
.setting-item button.delete-project-btn,
.setting-item button.cancel-subscription-btn,
.setting-item button.delete-account-btn {
  padding: 5px 10px;
  background-color: #dc3545; /* Red color for the buttons */
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-family: 'VT323', monospace;
}

/* Hover Effect for Delete, Cancel Subscription, and Delete Account Buttons */
.setting-item button.delete-project-btn:hover,
.setting-item button.cancel-subscription-btn:hover,
.setting-item button.delete-account-btn:hover {
  background-color: #c82333; /* Darker red color on hover */
}

/* Projects List */
.projects-list {
  list-style-type: none;
  padding: 0;
}

.projects-list li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

/* Confirmation Modal */
.confirmation-modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

/* Modal Content */
.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
}

/* Modal Actions */
.modal-actions {
  margin-top: 20px;
}

/* Modal Action Button */
.modal-actions button {
  padding: 5px 10px;
  margin-right: 10px;
  background-color: #ffa500 !important;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-family: 'VT323', monospace;
}

/* Modal Action Button Hover */
.modal-actions button:hover {
  background-color: #e57d00;
}


.loading-spinner {
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.yes-button,
.no-button {
    background-color: rgba(232, 106, 46, 0.9);
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    width: 40px; /* Set the width */
    height: 40px; /* Set the height */
}