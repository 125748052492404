.centered {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .grey-text {
    color: #888;
    font-size: 2em;
    text-align: center;
  }
  