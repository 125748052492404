/* EditorInfoForm.css */

/* Editor Info Form Section */
.editor-info-form-section {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
    max-width: 600px;
    margin: 0 auto;
}

.editor-info-form-section input[type="text"]:focus {
    outline: none; /* Remove default blue outline */
    border-color: #ff8c00; /* Set border color to orange when focused */
    box-shadow: 0 0 5px #ff8c00; /* Add orange box-shadow when focused */
  }

.editor-info-form-section input[type="email"]:focus {
    outline: none; /* Remove default blue outline */
    border-color: #ff8c00; /* Set border color to orange when focused */
    box-shadow: 0 0 5px #ff8c00; /* Add orange box-shadow when focused */
  }

/* Editor Info Title */
.editor-info h2 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #ffa500;
    font-family: 'Pacifico', cursive; 
}

/* Editor List */
.editor-info ul {
    list-style: none;
    padding: 0;
    margin-bottom: 20px;
}

.editor-info li {
    font-size: 16px;
    margin-bottom: 5px;
    color: #555;
    font-family: 'VT323', monospace; /* Retro font: VT323 */
}

/* Editor Form */
.editor-info form {
    display: flex;
    flex-direction: column;
}

/* Form Label */
.editor-info label {
    font-size: 16px;
    margin-bottom: 5px;
    color: #333;
    font-family: 'VT323', monospace; /* Retro font: VT323 */
}

/* Form Input */
.editor-info input {
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    font-family: 'VT323', monospace; /* Retro font: VT323 */
}

/* Error Message */
.error-message {
    color: red;
    margin-top: 10px;
}

/* Submit Button */
.editor-info button {
    background-color: #ff9800;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-family: 'VT323', monospace; /* Retro font: VT323 */
}

.editor-info button:hover {
    background-color: #e57d00;
}

  
/* Loading Spin Animation */
.editor-info button:disabled {
    position: relative; /* Ensure the button is set to relative positioning */
    background-color: rgba(232, 106, 46, 0.6); /* Adjust the background color to a duller shade */
    color: #ccc; /* Adjust the text color to a contrasting shade */
}

.editor-info button:disabled::after {
    content: '';
    border: 3px solid #fff;
    border-radius: 50%;
    border-top: 3px solid #ff9800; /* Loading color */
    width: 20px;
    height: 20px;
    position: absolute;
    top: 50%;
    left: calc(50% - 30px); /* Adjust the left position to align the spin with text */
    margin-top: -10px;
    animation: spin 1s linear infinite;
}

/* Success Message */
.success-message {
    color: rgb(162,96,70);
    margin-top: 10px;
    font-family: 'VT323', monospace; /* Retro font: VT323 */
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}


/* EditorInfoForm.css */
.remove-button {
    background-color: #ff6347 !important; /* Red color */
    color: #fff !important; /* White text color */
    padding: 6px 12px !important; /* Padding around the text */
    border: none !important; /* No border */
    border-radius: 4px !important; /* Rounded corners */
    cursor: pointer !important; /* Cursor style */
    margin-left: 10px !important; /* Add some space between buttons */
    font-size: 14px !important; /* Slightly smaller font size */
  }
  
  .remove-button:hover {
    background-color: #cc4c3c !important; /* Darker red color on hover */
  }
  