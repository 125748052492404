.signup-form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-image: url('../images/background.png'); /* Replace with your own background image */
    background-size: cover;
    background-position: center;
  }
  
  .signup-box {
    width: 80%;
    max-width: 400px; /* Adjust the maximum width as needed */
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    padding: 30px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
    height: 350px; /* Adjust the height as needed */
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .google-login {
    margin-bottom: 20px;
  }

  .google-button {
    color: rgba(255, 255, 255, 0.9); /* Adjust color as needed */
    background-color: transparent;
    border: none;
    font-weight: bold;
    cursor: pointer;
    transition: color 0.3s; /* Add transition for smoother hover effect */
  }
  
  .google-login button {
    font-weight: bold;
    color: rgba(255, 255, 255, 0.9); /* Text color */
    background-color: transparent;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    position: relative;
    width: 100%;
    height: 45px;
    min-height: 45px;
    transition: color 0.3s; /* Smooth color transition on hover */
    justify-content: center;
    align-items: center;
}

  .line {
    width: 100%;
    height: 2px;
    background-color: rgba(232, 106, 46, 0.9);
    margin: 20px 0;
  }
  
  
  h2 {
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
  }
  
  .signup-form,
  .verification-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }
  
  .email-label {
    font-size: 18px;
    color: #333;
    margin-bottom: 10px;
  }
  
  .email-input,
  .verification-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  
  .error-message {
    color: rgba(232, 106, 46, 0.9);
    margin-bottom: 10px;
  }

  .email-input:focus {
    outline: none; /* Remove default blue outline */
    border-color: #ff8c00; /* Set border color to orange when focused */
    box-shadow: 0 0 5px #ff8c00; /* Add orange box-shadow when focused */
  }
  
  .signup-button,
  .verify-button {
    background-color: rgba(232, 106, 46, 0.9);
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    position: relative;
    width: 100%;
    height: 45px;
    min-height: 45px;
  }

  /* Adjust the button text alignment */
.signup-button.loading,
.verify-button.loading {
  display: flex;
  justify-content: center;
  align-items: center;
}


  /* Dull button color */
.signup-button.disabled {
  background-color: rgba(232, 106, 46, 0.6); /* Adjust the opacity as needed */
  cursor: not-allowed;
}


  .line {
    display: flex;
    align-items: left;
    margin: 20px 0px;
    position: relative;
  }

 
  .line-divider {
    flex: 1;
    height: 1px;
    background-color: rgba(232, 106, 46, 0.9);
  }
  
  .line-text {
    font-size: 14px;
    color: rgba(232, 106, 46, 0.9); /* Burnt orange color */
    background-color: rgba(255, 255, 255, 0.988); /* Background color of the line text */
    padding: 0 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1; /* Bring the text to the front */
  }


  .create-account-heading {
    color: rgba(232, 106, 46, 0.9); /* Burnt orange color */
    text-align: center;
  }


  .loading-circle {
    position: absolute; /* Add this */
    top: 50%; /* Add this */
    left: 50%; /* Add this */
    transform: translate(-50%, -50%); /* Add this */
    border: 2px solid rgba(232, 106, 46, 0.9);
    border-top: 2px solid transparent;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite; /* Add this */
    transition: opacity 0.3s; /* Add this */
  }
  
  @keyframes spin {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
  
  /* Add new styles for verification layout */

  .verification-box {
   
    padding: 20px;
    text-align: center;
  }

  .verification-box input[type="text"]:focus {
    outline: none; /* Remove default blue outline */
    border-color: #ff8c00; /* Set border color to orange when focused */
    box-shadow: 0 0 5px #ff8c00; /* Add orange box-shadow when focused */
}
  
  .verification-box h2 {
    
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .welcome-message {
    font-size: 18px;
    margin-bottom: 30px;
  }
  
  /*   */
  
  .check-email-heading {
    font-size: 18px;
    color: #333;
  }
  
  .welcome-message {
    font-size: 16px;
    color: #333;
    margin-top: 10px;
  }



  .centered-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%; /* Ensure the container takes up the full height */
  }


  .verification-error {
    color: rgba(232, 106, 46, 0.9);
    margin-bottom: 10px;
  }

  /* Add this CSS to style the verification error message and link */
.signup-again-link a {
  color: #007bffba;
  text-decoration: none;
  font-weight: bold;
}

.signup-again-link a:hover {
  text-decoration: underline;
}
  
  