/* Add these styles to your existing CSS file or create a new one */

/* Review and Publish Section */
.review-and-publish {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
    max-width: 800px;
    margin: 0 auto;
  }
  
  /* Review and Publish Title */
  .review-and-publish h2 {
    font-size: 24px;
    margin-bottom: 10px;
    color: #ffa500;
    font-family: 'Pacifico', cursive;
  }
  
  /* General Text Styles */
  .review-and-publish p {
    font-size: 16px;
    color: #333;
    font-family: 'VT323', monospace;
  }
  
  
  /* Video Description and Tags */
  .review-and-publish p {
    margin-bottom: 10px;
  }
  
  /* Button Styles */
  .review-and-publish button1 {
    background-color: #ffa500;
    color: white;
    padding: 10px;
    margin-right: 10px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-family: 'VT323', monospace;
    text-align: center; /* Center the text */
    display: inline-block; /* Ensures inline block behavior */
    vertical-align: middle;
  }

 
  .review-and-publish button1:hover {
    background-color: #e57d00;
  }
  
  /* Input Styles */
  .review-and-publish input {
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 6px;
  }
  
  /* Additional Styles for Editing Instructions Input */
  .review-and-publish input {
    margin-bottom: 0; /* Remove margin-bottom for better alignment */
  }


  .review-and-publish img {
    display: block;
    margin: 20px auto; /* Center the image */
    max-width: 80%; /* Make sure the image doesn't exceed its container */
    height: auto; /* Maintain the aspect ratio of the image */
  }
  
  

  /* Button Container Styles */
.review-and-publish .button-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  /* Edit Container Styles */
  .review-and-publish .edit-container {
    flex-grow: 1;
    margin-right: 10px;
  }
  
  /* Additional Styles for Editing Instructions Input */
  .review-and-publish input {
    width: calc(100% - 10px); /* Adjust the width to account for margin-right */
  }


  .review-and-publish .request-edit-btn{
  background-color: #e74c3c; /* Red color for Request Edit button */
  color: white;
  padding: 10px;
  margin-right: 10px;
  margin-top: 10px; 
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-family: 'VT323', monospace;
  height: 47px; /* Adjust height as needed */
  width: 120px; /* Adjust width as needed */
}

.review-and-publish .approve-publish-btn {
  background-color: #ffa500; /* Orange color for Approve and Publish button */
}

.approve-publish-btn {
  background-color: #ffa500;
  color: white;
  font-family: 'VT323', monospace;
  font-size: 16px; /* Adjust font size as needed */
  border-radius: 4px; /* Adjust border radius for curved sides */
  padding: 10px 20px; /* Adjust padding for button size */
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  /*height: 47px; 
  width: 200px; */
  margin: 0 auto; /* Center the button horizontally */
}



.review-and-publish .request-edit-btn:hover{
    background-color: #c0392b;
}
.review-and-publish .approve-publish-btn:hover {
  background-color: #e57d00;
}
  

.review-and-publish input[type="text"]:focus {
    outline: none; /* Remove default blue outline */
    border-color: #ff8c00; /* Set border color to orange when focused */
    box-shadow: 0 0 5px #ff8c00; /* Add orange box-shadow when focused */
  }


  .publish-status {
    color: rgb(162,96,70) !important;
    margin-top: 10px;
    font-family: 'VT323', monospace; /* Retro font: VT323 */
}


/* Editor Dropdown Styles */
.review-and-publish .editor-dropdown {
  margin-bottom: 10px; /* Add margin bottom for spacing */
}

.review-and-publish .editor-dropdown label {
  margin-top: 15px;
  margin-right: 10px; /* Add margin right for spacing between label and select */
  font-family: 'Pacifico', cursive;
  color: #ffa500;
}

.review-and-publish .editor-dropdown select {
  width: 200px; /* Set width for select */
  padding: 5px; /* Add padding for select */
  border-radius: 4px; /* Add border radius for select */
  border: 1px solid #ccc; /* Add border for select */
  font-size: 16px; /* Set font size for select */
  font-family: 'VT323', monospace; /* Set font family for select */
}

.review-and-publish .editor-dropdown select:focus {
  outline: none; /* Remove default focus outline */
  border-color: #ff8c00; /* Set border color to orange when focused */
  box-shadow: 0 0 5px #ff8c00; /* Add orange box-shadow when focused */
}



