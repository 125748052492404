/* VideoReview.css */
  
  .message {
    color: green;
    font-weight: bold;
  }

  .read-more {
    display: block;
    margin-top: 5px;
    color: #098cf0; /* Blue color */
    text-decoration: none;
    font-weight: bold;
  }
  
  .read-more:hover {
    text-decoration: underline;
  }

  /* VideoReview.css */
.video-description-label {
    font-weight: bold;
    color: #c4c4c0fe; /* Off-white color */
  }
  
/* VideoReview.css */
.video-player {
    width: 100%; /* Set the desired width */
    max-width: 800px; /* Set a maximum width if needed */
    height: auto; /* Maintain aspect ratio */
  }

/* VideoReview.css */
.video-review {
    background-color: #1c1c1c;
    color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  }
  
  .video-title {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .video-description {
    font-size: 16px;
    margin-bottom: 20px;
  }
  
  .tags {
    font-size: 14px;
    color: #ccc;
    margin-bottom: 10px;
  }
  
  .video-player {
    width: 100%;
    height: 400px;
    border-radius: 10px;
    overflow: hidden;
  }
  
  .decision-form {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  
  .decision {
    cursor: pointer;
    padding: 10px 20px;
    border: 1px solid #cccccc;
    border-radius: 5px;
    font-size: 14px;
  }
  
  .decision.selected {
    background-color: #4ba836; /* Green for Approved */
    color: #fff;
  }
  
  .decision.denied.selected {
    background-color: #ff3333; /* Red for Denied */
  }
  
  .comment-input {
    width: 100%;
    margin-top: 20px;
    resize: vertical;
  }
  
  .submit-btn {
    margin-top: 20px;
  }
  
  .message {
    margin-top: 20px;
    font-size: 16px;
  }