:root {
    --plyr-color-main: #ff8c00; /* Change player color to #ff8c00 */
    --plyr-font-family: 'VT323', monospace; /* Change player font to 'VT323', monospace */
  }

  .video-player-plyr {
    margin: 20px 0px;
    max-width: 585px;
  }
  
  
  
  