.container1 {
    background-color: #ffffff;
    max-width: 800px; /* Adjust the width as needed */
    padding: 20px;
    margin: 0px auto;
    border-radius: 5px;
    box-shadow: 0 0 0px rgba(0, 0, 0, 0.1);
  }
  
  .header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .header h1 {
    color: #333;
  }
  
  .header p {
    color: grey;
  }
  
  .section {
    margin-bottom: 20px;
  }
  
  .section h2 {
    color: #333;
    text-align: left; /* Left-align heading */
    margin-top: 30px;
    margin-bottom: 10px; /* Add margin after each heading */
  }
  
  .section p {
    color: #555;
    text-align: left; /* Left-align content */
    margin-bottom: 5px; /* Add margin after each paragraph */
  }
  
  /* Add line gap after Terms of Service */
  .section:first-of-type {
    margin-top: 20px;
  }
  
  /* Italicize and color "Last Updated" information */
  .header p:last-of-type {
    color: grey;
    font-style: italic;
  }
  
  .footer {
    background: white;
    padding: 20px 0;
    text-align: center;
  }
  
  .footer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .channelLinkupStyles {
    font-size: 1.5rem;
    font-family: 'VT323', monospace;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: orange;
    margin-bottom: 10px;
  }
  
  .taglineStyles {
    font-size: 1.2rem;
    font-family: 'VT323', monospace;
    color: orange;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  
  .linkedinLogoStyles {
    width: 30px;
  }
  
  .rightsReservedStyles {
    color: black;
  }
  
  .navbar-brand {
    color: #333;
  }
  
  /* Additional styling for the Link component */
  .navbar-brand:hover {
    text-decoration: none;
    color: #555;
  }
  
  /* Media Queries */
  @media (max-width: 768px) {
    .container1 {
      max-width: 400px; /* Adjust the width as needed for smaller screens */
      padding: 10px;
    }
  
    .header1 h1 {
      font-size: 28px;
    }
  
    .section1 h2 {
      font-size: 22px;
    }
  
    .section1 p {
      font-size: 14px;
    }
  }
  

  /* Highlight the points */
.section p::before {
  content: attr(data-point);
  font-weight: bold;
  color: #000000; /* Highlight color */
  margin-right: 5px;
}

.left-side {
  margin-right: 50px; /* Adjust the margin as needed */
}
