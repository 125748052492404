.payment-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: url('../images/background.png'); /* Replace with your own background image */
  background-size: cover;
  background-position: center;
}

.payment-box {
  width: 80%;
  max-width: 400px; /* Adjust the maximum width as needed */
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  padding: 30px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  height: auto; /* Adjust the height as needed */
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 20px;
}

.payment-heading {
    color: rgba(232, 106, 46, 0.9); /* Burnt orange color */
    text-align: center;
    padding: 10px 0; /* Add padding to the top and bottom */
  }
  

.payment-button {
  background-color: rgba(232, 106, 46, 0.9);
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
  width: 100%;
  height: 45px;
  min-height: 45px;
  margin-top: 20px;
}

.payment-button.loading {
  display: flex;
  justify-content: center;
  align-items: center;
}

.payment-button.disabled {
  background-color: rgba(232, 106, 46, 0.6); /* Adjust the opacity as needed */
  cursor: not-allowed;
}

.promo-section {
  margin-top: 20px;
  text-align: center;
}

.promo-text {
  font-size: 16px;
  color: rgba(232, 106, 46, 0.9); /* Burnt orange color */
  background-color: #fff;
  padding: 0 20px;
  position: relative;
  display: inline-block;
}

.promo-text::before,
.promo-text::after {
  content: '';
  display: block;
  width: 50%;
  height: 1px;
  background-color: rgba(232, 106, 46, 0.9); /* Burnt orange color */
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.promo-text::before {
  left: -50%;
}

.promo-text::after {
  right: -50%;
}

.promo-input-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  padding: 10px 0; /* Add padding to the top and bottom */
}

.promo-input-container input[type="text"]:focus {
    outline: none; /* Remove default blue outline */
    border-color: #ff8c00; /* Set border color to orange when focused */
    box-shadow: 0 0 5px #ff8c00; /* Add orange box-shadow when focused */
}

.promo-input {
  width: 200px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-right: 10px;
}

.apply-promo-button {
  background-color: rgba(232, 106, 46, 0.9);
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  width: 80px; /* Set the width */
  height: 45px; /* Set the height */
}

.promo-message {
  color: red;
  font-size: 14px;
}

.payment-button:hover,
.apply-promo-button:hover {
  background-color: rgba(232, 106, 46, 1); /* Darken the color on hover */
}

  
  .subscription-price {
    font-size: 20px;
    color: #666; /* Dark text color */
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
  }
  
  .subscription-price span {
    font-size: 16px; /* Small font size for the '/mo' text */
    color: #666; /* Lighter text color */
  }

  .or-text {
    font-size: 18px;
    color: #999; /* Your color */
  }
  
  .verification-message {
    color: #cc4e2e; /* Green color */
    font-size: 16px;
    margin-top: 20px;
  }
  