.project-details-section {
  font-family: 'VT323', monospace;
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  text-align: left; /* Align content to the left */
}

.project-details-section h2 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #ffa500;
  font-family: 'Pacifico', cursive; 
}

.project-details-section label {
  font-size: 16px;
  display: block;
  margin-bottom: 8px;
}

.project-details-section input[type="text"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  margin-bottom: 16px; /* Increase margin for better spacing */
}

.project-details-section input[type="text"]:focus {
  outline: none; /* Remove default blue outline */
  border-color: #ff8c00; /* Set border color to orange when focused */
  box-shadow: 0 0 5px #ff8c00; /* Add orange box-shadow when focused */
}

.project-details-section .project-exists-message {
  font-size: 14px;
  color: red;
  margin-top: 8px;
  margin-bottom: 8px;
}

.project-details-section input[type="file"] {
  margin-top: 8px;
  margin-bottom: 16px;
  width: 100%;
  text-align: left;
}

.project-details-section .selected-files {
  font-size: 14px;
  margin-bottom: 16px;
}

.project-details-section .selected-files ul {
  list-style: none;
  padding: 0;
}

.project-details-section .selected-files li {
  margin-bottom: 4px;
}

.project-details-section button {
  background-color: #ff8c00;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 100%;
}

.project-details-section button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.project-details-section button:disabled:hover {
  background-color: #ccc;
  cursor: not-allowed;
}

.project-details-section button:hover {
  background-color: #e57d00;
}

.project-details-section .success-message {
  font-size: 16px;
  color: rgb(162,96,70);
  margin-top: 8px;
  margin-bottom: 8px;
}
