/* Reset some default styles */
body, h1, h2, h3, p, ul, li {
  margin: 0;
  padding: 0;
}

/* Main layout */
.user-dashboard {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

/* Header styles */
.header1 {
  background-color: #333;
  color: #fff ;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar-brand1 {
  color: white !important; /* Change the text color to white */
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2px; /* Increase letter spacing */
  font-family: 'VT323', monospace; /* Apply VT323 font for video game style */
  text-decoration: none;
}

.navbar-brand1:hover {
  color: #ccc !important; 
}

/* Logo styles */
.logo img {
  height: 40px;
  width: auto;
}

/* Account dropdown styles */
.account-dropdown {
  position: relative;
  display: inline-block;
  margin-right: 80px;
}

.account-dropdown button {
  background: none;
  border: none;
  color: #ffffffca !important;
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 2px; /* Increase letter spacing */
  font-family: 'VT323', monospace; /* Apply VT323 font for video game style */
}

.dropdown-content {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #333;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  
}

.dropdown-content a {
  color: #fff;
  text-decoration: none;
  padding: 0.5rem 1rem;
  transition: background-color 0.2s;
}

.dropdown-content a:hover {
  background-color: #444;
}


.account-dropdown button {
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
  
   /* Adjust this value to move "My Account" slightly to the left */
}

.dropdown-content {
  position: absolute;
  top: 100%;
   /* Adjust this value to align the dropdown with "My Account" button */
  /* ... (your existing styles) */
}

.email {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  margin-top: 10px; /* Add margin to separate email from "Log Out" button */
  color: #ffffffca !important;
  letter-spacing: 2px; /* Increase letter spacing */
  font-family: 'VT323', monospace; /* Apply VT323 font for video game style */
}


/* Dashboard content */
.dashboard-content {
  flex-grow: 1;
  display: flex;
}

/* Sidebar styles */
.sidebar {
  width: 300px;
  background-color: #f2f2f2;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  color: #ffffffca !important;
  cursor: pointer;
}

/* Sidebar button styles */
.sidebar button {
  background-color: #f2f2f2;
  color: #fff;
  border: none;
  padding: 0.5rem 1rem;
  margin-bottom: 0.5rem;
  cursor: pointer;
  font-size: 16px;
  text-align: left;
  transition: background-color 0.2s;
  
  border-radius: 5px; /* Add border-radius for curved corners */
}

.sidebar button:hover {
  background-color: #444;
}

/* Modern-style on active */
.sidebar button.active {
  background-color: #666;
}

/* Landing page content */
.landing-page {
  flex-grow: 1;
  padding: 2rem;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  
}

/* Projects dropdown */
.projects-dropdown {
  position: relative;
  display: inline-block;
  margin-left: 80px;
  color: #ffffffca !important;
  letter-spacing: 2px; /* Increase letter spacing */
  font-family: 'VT323', monospace; /* Apply VT323 font for video game style */
  
}

.projects-dropdown button {
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
  margin-right: 1rem;
  color: #ffffffca !important;
  letter-spacing: 2px; /* Increase letter spacing */
  font-family: 'VT323', monospace; /* Apply VT323 font for video game style */  
}

.projects-dropdown-content {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #333;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  
}

.projects-dropdown-content button {
  background-color: #333;
  color: #fff;
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  text-align: left;
  transition: background-color 0.2s;
}

.projects-dropdown-content button:hover {
  background-color: #444;
}



/* ProjectDetails.css */

.selected-files ul {
  list-style: none;
  padding-left: 0;
}

.selected-files li {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.selected-files .remove-file {
  background: none;
  border: none;
  color: black; /* Set the color to black */
  font-size: 12px;
  cursor: pointer;
  margin-left: 10px; /* Adjust as needed */
}

.selected-files .remove-file:focus {
  outline: none; /* Remove the focus outline */
}
