/* ContactPage.css */

body {
  margin: 0; /* Remove default margin */
  padding: 0; /* Remove default padding */
}



.contact-container {
  font-family: 'VT323', monospace; /* Apply VT323 font for video game style */
  background-color: #fff; /* White container background */
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  padding: 20px;
  width: 80%; /* Adjust the width as needed */
  max-width: 800px; /* Set a maximum width for the form */
  margin: 20px auto; /* Center the container horizontally and provide spacing */
  display: flex;
  justify-content: space-between; /* Space between "Contact Us" and contact information */
  align-items: center; /* Center the content vertically */
}



/* Add this CSS to style the submission message */
.submission-message {
  
  text-align: center; /* Center the text horizontally */
  padding: 1px; /* Add spacing around the message */
  color: rgb(0, 0, 0);/* Text color */
  border-radius: 5px; /* Rounded corners */
  margin-top: 20px; /* Add spacing from other content */
  margin-right: 65%;
}




.contact-info {
  flex: 1;
  text-align: left; /* Align content on the left */
  padding: 20px;
}

.contact-info h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.contact-form {
  flex: 2;
  text-align: center;
}

.contact-form input,
.contact-form textarea,
.contact-form button {
  width: 100%;
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
}

.contact-form button {
  background-color: #ff6600; /* Retro orange color */
  color: #fff;
  cursor: pointer;
}

/* Add more specific styling as needed */
