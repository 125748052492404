/* ContactForm.css */

.loading {
    position: relative;
  }
  
  .loading::before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 24px; /* Adjust the size of the circular loader */
    height: 24px; /* Adjust the size of the circular loader */
    margin-top: -12px; /* Half of the height */
    margin-left: -12px; /* Half of the width */
    border: 2px solid transparent;
    border-top: 2px solid #fff; /* Color of the circular loader */
    border-radius: 50%;
    animation: spin 1s linear infinite; /* Animation properties */
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  