/* Video Player Container */
.video-player-container {
  max-width: 800px;
  margin: 20px auto;
  font-family: 'VT323', monospace;
}

/* Raw Clips Container */
.raw-clips-container {
  margin-top: 30px;
}

/* Raw Clips Heading */
.raw-clips-heading {
  font-family: 'Pacifico', cursive;
  margin-bottom: 30px;
  color: #ff9800;
}

/* Download All Button */
.download-all-button {
  background-color: #ff9800;
  color: white;
  padding: 8px 10px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-family: 'VT323', monospace;
  display: block;
  margin: 0 auto;
}

.download-all-button:hover {
  background-color: #e57d00;
}

/* Small Video Players Container */
.small-video-players-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

/* Small Video Player Container */
.small-video-player-container {
  margin-right: 20px;
  margin-bottom: 20px;
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
  position: relative;
  background-color: #f4f4f4;
  flex: 0 0 calc(33.33% - 20px);
  max-width: calc(33.33% - 20px);
  box-sizing: border-box;
}

.small-video-player-container:hover {
  transform: scale(1.05);
}

.small-video-player {
  width: 100%;
  height: auto;
  display: block;
  object-fit: cover;
  border: none;
  border-radius: 0;
}

.play-button-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
  color: #fff;
  cursor: pointer;
  display: none;
}

.small-video-player-container:hover .play-button-overlay {
  display: block;
}

/* Content Item */
.content-item-2 {
  position: relative;
  display: inline-block;
  width: 340px;
  height: 280px;
  margin: 10px;
  text-align: center;
  overflow: hidden;
}

.content-item-2 img,
.content-item-2 video {
  display: block; /* Ensure images and videos fill the container */
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.file-name {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0;
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  font-size: 14px;
  text-align: center;
  font-family: 'VT323', monospace;
}

.file-actions {
  position: absolute;
  top: 5px;
  right: 5px;
}

.download-file-button {
  background-color: #ff9800 !important;
  color: #fff !important; /* White text color */
  padding: 6px 12px !important; /* Padding around the text */
  border: none !important; /* No border */
  border-radius: 4px !important; /* Rounded corners */
  cursor: pointer !important; /* Cursor style */
  margin-left: 10px !important; /* Add some space between buttons */
  font-size: 14px !important; /* Slightly smaller font size */
  cursor: pointer;
}

.download-file-button:hover {
  background-color: #e57d00;
}

/* File Icon */
.file-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80%;
  overflow: hidden;
  margin-top: 12%;
}

.file-icon img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.download-message {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}


/* Responsive Styles for Mobile */
@media (max-width: 600px) {
  /* Video Player Container */
  .video-player-container {
    max-width: 100%;
    margin: 20px auto;
    padding: 0 10px;
  }

  /* Content Item */
  .content-item {
    width: 100%;
    height: auto;
    margin: 10px 0;
    border-radius: 0;
    border: none;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }


  /* Small Video Player Container */
  .small-video-player-container {
    flex: 0 0 calc(100% - 20px);
    max-width: calc(100% - 20px);
  }
}

