/* Folder Upload Section */
.folder-upload-section {
  max-width: 800px;
  margin: 20px auto;
  font-family: 'VT323', monospace;
  padding: 20px;
  background-color: #f4f4f4;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Folder Upload Heading */
.folder-upload-section h2 {
  font-family: 'Pacifico', cursive;
  color: #ff9800;
  text-align: center;
  margin-bottom: 20px;
}

/* File Input */
.folder-upload-section input[type="file"] {
  display: block;
  margin: 0 auto 20px;
  font-size: 16px;
  padding: 8px 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #fff;
  font-family: 'VT323', monospace;
}

/* Selected Files Container */
.selected-files-container {
  margin-bottom: 20px;
  text-align: center; /* Center the content */
}

.success-message {
  text-align: center; /* Center the content */
}

/* Selected Files */
.selected-files-1 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 10px;
}

/* Selected File */
.selected-file {
  background-color: #e0e0e0;
  padding: 8px 10px;
  border-radius: 4px;
  margin: 5px;
  display: flex;
  align-items: center;
  font-family: 'VT323', monospace;
}

/* Remove File Button */
.remove-file {
  margin-left: 10px;
  color: #ff9800;
  cursor: pointer;
}

/* Upload Button */
.folder-upload-section button2 {
  background-color: #ff9800;
  color: white;
  padding: 8px 10px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-family: 'VT323', monospace;
  display: block;
  margin: 0 auto;
  width: fit-content; /* Make the button width fit its content */
}


.folder-upload-section button2:hover {
  background-color: #e57d00;
}

/* Content Items Container */
.content-items-container {
  margin-top: 30px;
}

/* Content Items Container Heading */
.content-items-container h3 {
  font-family: 'Pacifico', cursive;
  color: #ff9800;
  text-align: center;
  margin-bottom: 20px;
}

/* Content Item */
.content-item-2 {
  position: relative;
  display: inline-block;
  width: 360px;
  height: 280px;
  margin: 10px;
  text-align: center;
  overflow: hidden;
}

.content-item-2 img,
.content-item-2 video {
  display: block; /* Ensure images and videos fill the container */
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.file-name {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0;
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  font-size: 14px;
  text-align: center;
  font-family: 'VT323', monospace;
}

/* File Icon */
.file-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80%;
  overflow: hidden;
  margin-top: 12%;
}

.file-icon img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

/* Responsive Styles for Mobile */
@media (max-width: 600px) {
  /* Folder Upload Section */
  .folder-upload-section {
    max-width: 100%;
    margin: 20px auto;
    padding: 10px;
  }

  /* Content Item */
  .content-item {
    width: 100%;
    height: auto;
    margin: 10px 0;
    border-radius: 0;
    border: none;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .file-name {
    position: static;
    margin-top: 5px;
    padding: 5px;
  }
}


.file-actions {
  position: absolute;
  top: 5px;
  right: 5px;
}

.delete-file-button {
  background-color: #ff4d4d !important;
  color: #fff !important; /* White text color */
  padding: 6px 12px !important; /* Padding around the text */
  border: none !important; /* No border */
  border-radius: 4px !important; /* Rounded corners */
  cursor: pointer !important; /* Cursor style */
  margin-left: 10px !important; /* Add some space between buttons */
  font-size: 14px !important; /* Slightly smaller font size */
  cursor: pointer;
}

.delete-file-button:hover {
  background-color: #ff2020 !important;
}