.signup-page {
  height: 100vh;
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.center-content {
  width: 100%; /* Center the content horizontally within the page */
}

.header-title {
  font-size: 20px; /* Adjust the font size as needed */
}




